import { createContext, MutableRefObject } from 'react'
import Bowser from 'bowser'
import { Device } from '~/app/utils/deviceParser'
import { ToastHandler } from '@yanolja-rnd/ya-com-toast'
import { DateTime } from 'luxon'

export interface GlobalState {
  deviceInfo: Bowser.Parser.ParsedResult | null
  device: Device | null
  redirect: string | string[] | undefined
  now: string
  toastRef: MutableRefObject<ToastHandler | undefined> | null
}

export const GlobalContext = createContext<GlobalState>({
  deviceInfo: null,
  device: null,
  redirect: undefined,
  now: DateTime.now().toISO(),
  toastRef: null,
})
