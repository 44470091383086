import React, { useRef, useState } from 'react'
import Head from 'next/head'
import App, { AppProps } from 'next/app'
import { DateTime, Settings } from 'luxon'
import Bowser from 'bowser'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider, Global } from '@emotion/react'

import { GlobalContext, GlobalState } from '~/app/GlobalProvider'
import { theme } from '~/app/styled'
import { getDevice, deviceParser } from '~/app/utils/deviceParser'
import reset, { resetFix, fix, app } from '~/app/styled/reset'
import Loading from '~/app/component/common/loading'
import { useRouter } from 'next/router'
import GlobalToast from '~/app/component/common/Toast/GlobalToast'
import { ToastHandler } from '@yanolja-rnd/ya-com-toast'
import qs from 'qs'
import axios from 'axios'

Settings.defaultLocale = 'ko-KR'
Settings.defaultZone = 'UTC+9'

interface PointAppProps extends AppProps {
  deviceInfo: Bowser.Parser.ParsedResult
  now: string
  err: Error
}

function CustomApp({ Component, pageProps, deviceInfo, now, err }: PointAppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: 0,
          },
        },
      })
  )
  const router = useRouter()
  const redirect = router.query['redirect']
  const toastRef = useRef<ToastHandler>()
  const globalValue: GlobalState = {
    deviceInfo,
    device: getDevice(deviceInfo.os),
    redirect,
    now,
    toastRef,
  }

  return (
    <>
      <Head>
        <title>야놀자 | 여행의 모든 것, 한 번에 쉽게</title>
        <meta
          name="viewport"
          content="initial-scale=1, maximum-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <GlobalContext.Provider value={globalValue}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider theme={theme}>
              <Global styles={[reset, resetFix, fix, app]} />
              <Component {...pageProps} err={err} />
              <Loading />
              <div id={'modal-root'} style={{ position: 'absolute', zIndex: 1, top: 0 }}>
                <div id={'modal-dialog'} />
                <div id={'modal-toast'} />
              </div>
              <GlobalToast toastRef={toastRef} />
            </ThemeProvider>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </GlobalContext.Provider>
    </>
  )
}

const makeCookieString = (key, value) => {
  const maxAge = 60 * 60 * 24 * 365 * 5 // 5 years
  return `${key}=${value}; Max-Age=${maxAge}; Domain=.yanolja.com; Path=/; SameSite=Lax`
}

/*
 * Warning: You have opted-out of Automatic Static Optimization due to `getInitialProps` in `pages/_app`. This does not opt-out pages with `getStaticProps`
 * _app에 getInitialProps를 사용하게 되면 자동으로 모든 페이지는 서버에서 실행되어 getStaticProps가 비활성화 됨
 * 페이지마다 다른 설정을 원한다면 HOC로 이 문제를 해결할수 있음 - https://nextjs.org/docs/messages/opt-out-auto-static-optimization
 * */
CustomApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext)
  const userAgent = appContext.ctx.req
    ? appContext.ctx.req.headers['user-agent']
    : navigator.userAgent
  const Browser = Bowser.parse(userAgent)
  const now = DateTime.now().toISO()

  // Native에서 접근 시 헤더에 담긴 정보를 활용한다.
  if (typeof window === 'undefined' && appContext.ctx.req?.url !== '/') {
    // Creates ywt session
    const { req, res } = appContext.ctx
    const {
      ywt = '',
      token = '',
      cookie = '',
      version = '',
      ['app-device']: device = '',
    } = req.headers
    // native 환경에서는 쿠키가 아니라 헤더에 담아서 보내줌.
    const headerCgntId = req.headers?.cgntid || req.headers?.cgntId || ''
    const cookieCgntId = req.cookies?.cgntId ?? ''
    const cgntId = headerCgntId || cookieCgntId
    const cookieForHeader: string[] = []
    const cookieForResponse: string[] = []
    const deviceInfo = deviceParser({ Browser, device, version })

    if (device) {
      cookieForResponse.push(makeCookieString('device', device))
      cookieForHeader.push(qs.stringify({ device }, { encodeValuesOnly: true }))
    }
    if (version) {
      cookieForResponse.push(makeCookieString('version', version))
      cookieForHeader.push(qs.stringify({ version }, { encodeValuesOnly: true }))
    }
    if (cgntId) {
      cookieForResponse.push(makeCookieString('cgntId', cgntId))
      cookieForHeader.push(qs.stringify({ cgntId }, { encodeValuesOnly: true }))
    }

    appContext.ctx.req.headers.cookie = [cookie, cookieForHeader.join('; ')].join('; ')

    const r = await axios({
      method: 'post',
      url: 'http://localhost:3000/api/auth/ywt',
      headers: {
        ywt,
        token,
        cookie, // 이미 클라이언트에서 넘어온 쿠키가 있다면 새로운 불필요하게 yanolja_sid 를 생성하지 않도록 연결
        cgntid: cgntId,
        device: deviceInfo.device,
        version: deviceInfo.appVersion,
        ['os-version']: deviceInfo.osVersion,
        ['user-agent']: userAgent,
      },
    }).catch(err => console.log(err))

    if (r && r.headers && r.headers['set-cookie']) {
      /**
       * ywt 를 이용해 로그인 처리에 성공 했다면 이후 SSR graphql 에서 사용할 수 있도록 yanolja_sid 를 연결해줌
       * client 까지 응답이 한번 내려간 후라면 set-cookie 에 의해 쿠키가 설정된 상황이므로 이 로직을 수행하지 않음
       */
      const responseCookie = r.headers['set-cookie']
      if (typeof responseCookie === 'string') {
        cookieForResponse.push(responseCookie)
      } else if (Array.isArray(responseCookie)) {
        responseCookie.forEach(c => cookieForResponse.push(c))
      }
      const newCookies = responseCookie.map(cookie => {
        const [keyValue] = cookie.split(';')
        return keyValue.trim()
      })
      appContext.ctx.req.headers.cookie = [
        appContext.ctx.req.headers.cookie,
        newCookies.join('; '),
      ].join('; ')
    }
    if (cookieForResponse.length > 0) {
      res.setHeader('Set-Cookie', cookieForResponse)
    }
  }

  return {
    ...appProps,
    deviceInfo: Browser,
    now,
  }
}

export default CustomApp
