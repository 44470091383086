import { Modal } from '@yanolja-rnd/ya-com-modal'
import { FC } from 'react'
import { useIsFetching } from 'react-query'
import { useIsMutating } from 'react-query'
import Spinner, { LoadingContainer } from './Spinner'

const Loading: FC = () => {
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()
  const isLoading = isFetching + isMutating

  return (
    <Modal isOpened={isLoading > 0} isDetached={isLoading <= 0} type={'refresh-indicator'}>
      <LoadingContainer>{isLoading > 0 && <Spinner />}</LoadingContainer>
    </Modal>
  )
}

export default Loading
