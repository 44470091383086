import { Toast, ToastHandler } from '@yanolja-rnd/ya-com-toast'
import React, { FC } from 'react'

interface GlobalToastProps {
  toastRef: React.MutableRefObject<ToastHandler | undefined>
}
const GlobalToast: FC<GlobalToastProps> = ({ toastRef }) => {
  return <Toast toastRef={toastRef} />
}

export default GlobalToast
