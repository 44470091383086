import '@emotion/react'
import styled from '@emotion/styled'
import { CreateStyled } from '@emotion/styled'
import Colors from './color'
import Size from './size'
import Animations from '~/app/styled/animations'

/*
 * Emotion 11 Theme 타입 적용
 * */
declare module '@emotion/react' {
  export interface Theme {
    colors: typeof Colors
    size: typeof Size
    animations: typeof Animations
  }
}

export const theme = {
  colors: { ...Colors },
  size: { ...Size },
  animations: { ...Animations },
}

export default styled as CreateStyled
