import React, { FC } from 'react'
import styled from '@emotion/styled'
import Animations from '~/app/styled/animations'
import { Color } from '@yanolja-rnd/ya-com-core'

export const LoadingContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`

const Dot = styled.div<{ delay: number }>`
  width: 8px;
  height: 8px;
  background: ${Color.yablue400};
  border-radius: 50%;
  margin: 8px;
  opacity: 0;
  animation: ${Animations.Blink} 1.5s infinite;
  animation-delay: ${({ delay }) => delay}ms;
`

const Spinner: FC = () => {
  return (
    <Container>
      <Dot delay={0} />
      <Dot delay={200} />
      <Dot delay={400} />
    </Container>
  )
}

export default Spinner
