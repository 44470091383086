import Bowser from 'bowser'

export enum Device {
  ANDROID = 'android',
  IOS = 'ios',
  ETC = 'etc',
}

const getDevice = (os: Bowser.Parser.OSDetails) => {
  switch (os.name) {
    case 'Android':
      return Device.ANDROID
    case 'iOS':
      return Device.IOS
    default:
      return Device.ETC
  }
}

type DeviceInfoType = {
  Browser: Bowser.Parser.ParsedResult
  device: string
  version: string
}
const deviceParser = ({ Browser, device, version }: DeviceInfoType) => {
  const isApp = !!device || !!version

  return {
    device: isApp ? device.toUpperCase() : Browser.platform.type === 'mobile' ? 'MWEB' : 'PC',
    appVersion: version || '',
    osVersion: Browser.os.version || '',
  }
}

export { getDevice, deviceParser }
